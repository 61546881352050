import React from "react";
import { JSX } from "react/jsx-runtime";

const SwapIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.44354 12.5435C3.44354 12.9269 3.75116 13.2381 4.13019 13.2381C4.50923 13.2381 4.81685 12.9269 4.81685 12.5435V2.87692L7.00409 5.0977C7.27143 5.36997 7.70539 5.36997 7.97456 5.09955C8.24373 4.82913 8.24465 4.38924 7.97639 4.11696L4.61635 0.704294C4.42042 0.504256 4.12379 0.445912 3.86835 0.552413C3.61108 0.659841 3.44354 0.912665 3.44354 1.1942V12.5435ZM0.769782 5.302C0.594913 5.302 0.419128 5.23439 0.285459 5.09918C0.0162889 4.82876 0.0153733 4.38979 0.283628 4.11752L1.62032 2.75893C1.88858 2.48666 2.32254 2.48666 2.59171 2.75708C2.85997 3.0275 2.86088 3.4674 2.59354 3.73967L1.25594 5.09733C1.12227 5.23439 0.945566 5.302 0.769782 5.302ZM12.0488 15.4472C12.1331 15.4824 12.2219 15.5 12.3107 15.5C12.4901 15.5 12.6659 15.4296 12.7968 15.2953L15.6725 12.3735C15.9399 12.1012 15.939 11.6613 15.6707 11.3909C15.4015 11.1205 14.9667 11.1214 14.6993 11.3928L12.9973 13.1236V3.45517C12.9973 3.07177 12.6906 2.7606 12.3107 2.7606C11.9316 2.7606 11.624 3.07177 11.624 3.45517V14.8054C11.624 15.087 11.7916 15.3398 12.0488 15.4472ZM9.80025 13.2396C9.93483 13.3766 10.1115 13.4442 10.2873 13.4442C10.4622 13.4442 10.638 13.3766 10.7716 13.2414C11.0408 12.971 11.0417 12.532 10.7744 12.2598L9.43769 10.9012C9.16852 10.6289 8.73456 10.6289 8.4663 10.8993C8.19713 11.1698 8.19622 11.6096 8.46356 11.8819L9.80025 13.2396Z" fill={props?.color}/>
  </svg>
  );
};

export default SwapIcon;
