import { Trans } from '@lingui/macro'
import { InterfacePageName } from '@uniswap/analytics-events'
import { Trace } from 'analytics'
import { useIsMobile } from 'nft/hooks'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

import darkImage from '../../assets/images/comming-soon-light.png'
import lightImage from '../../assets/images/comming-soon-light.png'

const Image = styled.img`
  max-width: 510px;
  width: 100%;
  padding: 0 75px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled(Container)`
  gap: 30px;
`

const PageWrapper = styled(Container)`
  flex: 1;
  justify-content: center;
  gap: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: space-between;
    padding-top: 64px;
  }
`

export default function CommingSoon() {
  const isDarkMode = useIsDarkMode()
  const isMobile = useIsMobile()

  const Title = isMobile ? ThemedText.HeadlineLarge : ThemedText.Hero
  const Paragraph = isMobile ? ThemedText.LabelSmall : ThemedText.HeadlineMedium

  return (
    <PageWrapper>
      <Trace page={InterfacePageName.NOT_FOUND} shouldLogImpression>
        <Header>
          <Image src={isDarkMode ? darkImage : lightImage} alt="Liluni" />
          <Container>
            <Title fontWeight={700}>Comming Soon</Title>
            <Paragraph fontWeight={385} color="neutral1">
              <Trans>This feature is under development</Trans>
            </Paragraph>
          </Container>

        </Header>

      </Trace>
    </PageWrapper>
  )
}
