import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { TraceEvent } from 'analytics'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import styled from 'styled-components'
import { BREAKPOINTS, ExternalLink, StyledRouterLink } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

import { FacebookIcon, TelegramIcon, TwitterIcon } from './Icons'

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  max-width: 1440px;
  padding-top: 20px;
  margin-top: 80px;
  border-top: 2px solid ${({ theme }) => theme.csborder};

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoSectionLeft = styled(LogoSection)`
  display: none;
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
`

const LogoSectionBottom = styled(LogoSection)`
  display: flex;
  width: 100%;
  margin-bottom: 72px;
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`

const StyledLogo = styled.img`
  width: 72px;
  height: 72px;
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  /* margin: 20px 0 0 0; */
  justify-content: flex-end ;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    gap: 12px;
  }
  
`

const SocialLink = styled.a`
  display: flex;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
   width: 20px;
   height: 20px;
  }
`

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 200px;
  margin: 20px 0 0 0;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    margin: 0;
  }
`

const LinkGroupTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 535;
`

const ExternalTextLink = styled(ExternalLink)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutral2};
`

const TextLink = styled(StyledRouterLink)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.neutral2};
`

const Copyright = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin: 1rem 0 0 0;
  color: ${({ theme }) => theme.neutral1};
`
const CsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
`
const LogoSectionContent = () => {
  const isDarkMode = useIsDarkMode()
  return (
    <CsFlex>
      <div><Copyright>© {new Date().getFullYear()} TrustDEX</Copyright></div>

      <SocialLinks>
        <SocialLink href="#/?intro=true" rel="noopener noreferrer">
          <FacebookIcon size={28} />
        </SocialLink>
        <SocialLink href="#/?intro=true" rel="noopener noreferrer">
          <TwitterIcon size={28} />
        </SocialLink>
        <SocialLink href="https://t.me/tdcchat" rel="noopener noreferrer">
          <TelegramIcon size={28} />
        </SocialLink>
      </SocialLinks>
    </CsFlex>
  )
}

export const AboutFooter = () => {
  const shouldDisableNFTRoutes = useDisableNFTRoutes()
  return (
    <Footer>
      <LogoSectionLeft>
        <LogoSectionContent />
      </LogoSectionLeft>
      <LogoSectionBottom>
        <LogoSectionContent />
      </LogoSectionBottom>
    </Footer>
  )
}
