import { BrowserEvent, SharedEventName } from '@uniswap/analytics-events'
import { TraceEvent } from 'analytics'
import { Link } from 'react-router-dom'
import { Flex } from 'rebass'
import styled, { DefaultTheme } from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

export enum CardType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

const StyledCard = styled.div<{ $isDarkMode: boolean; $backgroundImgSrc?: string; $type: CardType }>`
  display: flex;
  background: ${({ theme }) => theme.backgroundCard};
  box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.10);
  /* background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-origin: border-box; */
  /* background: transparent !important; */
  /* background: ${({ theme }) => theme.background}; */

  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  text-decoration: none;
  color: ${({ theme }) => theme.neutral1};
  padding: 32px 24px;
  /* height: 420px; */
  border-radius: 24px;
  /* border: 1px solid ${({ theme, $type }) => ($type === CardType.Primary ? 'transparent' : theme.surface3)}; */
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} border`};

  /* &:hover { */
    border: 2px solid ${({ theme, $isDarkMode }) => ($isDarkMode ? theme.background : theme.surface3)};
  /* } */
  /* @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    height: ${({ $backgroundImgSrc }) => ($backgroundImgSrc ? 600 : 500)}px;
  } */
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    padding: 32px;
  }
  @media screen and (max-width: 600px){
    flex-direction: row-reverse;
    padding: 32px 16px;
    gap: 10px;
  }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 535;
  color: ${({ theme }) => theme.neutral2};
  text-align: center;
  white-space: nowrap;
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    text-align: left;
    font-size: 28px;
    line-height: 36px;
  }
`

const getCardDescriptionColor = (type: CardType, theme: DefaultTheme) => {
  switch (type) {
    case CardType.Secondary:
      return theme.neutral2
    default:
      return theme.neutral1
  }
}

const CardDescription = styled.div<{ type: CardType }>`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme, type }) => getCardDescriptionColor(type, theme)};
  padding: 0 40px;
  max-width: 480px;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    font-size: 20px;
    line-height: 28px;
    max-width: 480px;
  }
  @media screen and (max-width: 600px){
    justify-content: flex-start;
    text-align: left;
    padding: 0;
    font-size: 14px;
  }
`

const CardCTA = styled(CardDescription)`
  color: ${({ theme }) => theme.neutral2};
  font-weight: 535;
  margin: 24px 0 0;
  padding: 10px 8px;
  font-size: 16px;
  width: 60%;
  border: ${({ theme }) => `2px solid ${theme.surface3}`};
  cursor: pointer;
  border-radius:90px;
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  &:hover {
    opacity: 0.6;
  }
  @media screen and (max-width: 600px){
    margin: 0;
    padding: 0;
    width: unset;
    border: 2px solid transparent;
    color: ${({ theme }) => theme.accent1};
  }
`

const Card = ({
  type = CardType.Primary,
  title,
  description,
  cta,
  to,
  external,
  backgroundImgSrc,
  icon,
  elementName,
}: {
  type?: CardType
  title: string
  description: string
  cta?: string
  to: string
  external?: boolean
  backgroundImgSrc?: string
  icon?: React.ReactNode
  elementName?: string
}) => {
  const isDarkMode = useIsDarkMode()
  return (
    <TraceEvent events={[BrowserEvent.onClick]} name={SharedEventName.ELEMENT_CLICKED} element={elementName}>
      <StyledCard
        as={external ? 'a' : Link}
        to={external ? undefined : to}
        href={external ? to : undefined}
        target={external ? '_blank' : undefined}
        rel={external ? 'noopenener noreferrer' : undefined}
        
        $isDarkMode={isDarkMode}
        $type={type}
      >
        <CsImg width="200px" height="200px" src={backgroundImgSrc}/>
        <CsFlex>
          <TitleRow>
            <CardTitle>{title}</CardTitle>
            {icon}
          </TitleRow>
          <CardDescription type={type}>
            {description}
          </CardDescription>
          <CardCTA type={type}>{cta}</CardCTA>
        </CsFlex>
      </StyledCard>
    </TraceEvent>
  )
}

export default Card

const CsFlex = styled(Flex)`
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 600px){
    align-items: flex-start;
    gap: 10px;
  };
`
const CsImg = styled.img`
  width: 200px;
  height: 200px;
  @media screen and (max-width: 600px){
    width: 120px;
    height: 120px;
  };
`