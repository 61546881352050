import { ChainId } from '@itdeltalabs/trustdex-sdk-core'
import { PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'

export const getPERMIT2ADDRESS = (chainId?: number) => {
  switch (chainId) {
    case ChainId.ONUS_TEST:
      return '0x134ad5AA7114eD1742860e1A5555Fe60f6ac678F'
    case ChainId.ONUS_MAINNET:
      return '0xA95f56aCcfD989418101c886E94A63b67AFfd8A6'
    default:
      return PERMIT2_ADDRESS
  }
}