import React from "react";
import { JSX } from "react/jsx-runtime";

const PowerIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
  <svg width="22" height="22" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3433 16.1563H17.6956C17.5831 16.1563 17.4777 16.2055 17.4074 16.2922C17.2433 16.4914 17.0675 16.6836 16.8824 16.8664C16.1251 17.6245 15.2281 18.2286 14.2409 18.6453C13.2183 19.0773 12.1191 19.2989 11.0089 19.2969C9.88626 19.2969 8.79876 19.0766 7.77689 18.6453C6.78977 18.2286 5.89276 17.6245 5.13548 16.8664C4.37685 16.111 3.77193 15.2155 3.35423 14.2297C2.92064 13.2078 2.70267 12.1227 2.70267 11C2.70267 9.87738 2.92298 8.79222 3.35423 7.77035C3.77142 6.78363 4.37142 5.89535 5.13548 5.13363C5.89954 4.37191 6.78783 3.77191 7.77689 3.35472C8.79876 2.92347 9.88626 2.70316 11.0089 2.70316C12.1316 2.70316 13.2191 2.92113 14.2409 3.35472C15.23 3.77191 16.1183 4.37191 16.8824 5.13363C17.0675 5.31879 17.241 5.51097 17.4074 5.70785C17.4777 5.79457 17.5855 5.84379 17.6956 5.84379H19.3433C19.4909 5.84379 19.5824 5.67972 19.5003 5.5555C17.7027 2.76175 14.5574 0.912535 10.9831 0.92191C5.36751 0.935973 0.86517 5.49457 0.92142 11.1032C0.97767 16.6227 5.47298 21.0782 11.0089 21.0782C14.5738 21.0782 17.705 19.2313 19.5003 16.4446C19.58 16.3203 19.4909 16.1563 19.3433 16.1563ZM21.4269 10.8524L18.1011 8.22738C17.9769 8.12894 17.7964 8.218 17.7964 8.37504V10.1563H10.437C10.3339 10.1563 10.2495 10.2407 10.2495 10.3438V11.6563C10.2495 11.7594 10.3339 11.8438 10.437 11.8438H17.7964V13.625C17.7964 13.7821 17.9792 13.8711 18.1011 13.7727L21.4269 11.1477C21.4493 11.1302 21.4674 11.1077 21.4799 11.0822C21.4924 11.0566 21.4988 11.0285 21.4988 11C21.4988 10.9716 21.4924 10.9435 21.4799 10.9179C21.4674 10.8923 21.4493 10.8699 21.4269 10.8524Z" fill={props.color}/>
  </svg>
  );
};

export default PowerIcon;

