import { Currency } from '@itdeltalabs/trustdex-sdk-core'
import { Position } from '@itdeltalabs/trustdex-v3-sdk'
import { Trans } from '@lingui/macro'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { Break } from 'components/earn/styled'
import HoverInlineText from 'components/HoverInlineText'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import RateToggle from 'components/RateToggle'
import { RowBetween, RowFixed } from 'components/Row'
import JSBI from 'jsbi'
import { ReactNode, useCallback, useState } from 'react'
import { Bound } from 'state/mint/v3/actions'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

const CsPosition = styled.div`
  display: grid;
  justify-content: space-between;
  gap: 20px;
  grid-template-columns: 31% 31% 31%;
`
const CsPositionV2 = styled.div`
  display: grid;
  justify-content: space-between;
  gap: 12px;
  grid-template-columns: 48% 48%;
`

const CsGridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1fr;
  @media screen and (max-width: 600px){
   display: flex;
   flex-direction: column;
  }
`
const CsGridBox = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`
const CsRowBetween = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media screen and (max-width:600px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

  }
`
const CsRowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`



export const PositionPreview = ({
  position,
  title,
  inRange,
  baseCurrencyDefault,
  ticksAtLimit,
}: {
  position: Position
  title?: ReactNode
  inRange: boolean
  baseCurrencyDefault?: Currency
  ticksAtLimit: { [bound: string]: boolean | undefined }
}) => {
  const theme = useTheme()

  const currency0 = unwrappedToken(position.pool.token0)
  const currency1 = unwrappedToken(position.pool.token1)

  // track which currency should be base
  const [baseCurrency, setBaseCurrency] = useState(
    baseCurrencyDefault
      ? baseCurrencyDefault === currency0
        ? currency0
        : baseCurrencyDefault === currency1
          ? currency1
          : currency0
      : currency0
  )

  const sorted = baseCurrency === currency0
  const quoteCurrency = sorted ? currency1 : currency0

  const price = sorted ? position.pool.priceOf(position.pool.token0) : position.pool.priceOf(position.pool.token1)

  const priceLower = sorted ? position.token0PriceLower : position.token0PriceUpper.invert()
  const priceUpper = sorted ? position.token0PriceUpper : position.token0PriceLower.invert()

  const handleRateChange = useCallback(() => {
    setBaseCurrency(quoteCurrency)
  }, [quoteCurrency])

  const removed = position?.liquidity && JSBI.equal(position?.liquidity, JSBI.BigInt(0))

  return (
    <AutoColumn gap="md" style={{ marginTop: '0.5rem' }}>
      <LightCard>
        <AutoColumn gap="md">
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency0} />
              <ThemedText.BodySecondary ml="8px">{currency0?.symbol}</ThemedText.BodySecondary>
            </RowFixed>
            <ThemedText.BodySecondary>{position.amount0.toSignificant(4)}</ThemedText.BodySecondary>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency1} />
              <ThemedText.BodySecondary ml="8px">{currency1?.symbol}</ThemedText.BodySecondary>
            </RowFixed>
            <ThemedText.BodySecondary>{position.amount1.toSignificant(4)}</ThemedText.BodySecondary>
          </RowBetween>
          <Break />
          <RowBetween>
            <ThemedText.BodySecondary>
              <Trans>Fee Tier</Trans>
            </ThemedText.BodySecondary>
            <ThemedText.BodySecondary>
              <Trans>{position?.pool?.fee / 10000}%</Trans>
            </ThemedText.BodySecondary>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      <AutoColumn gap="md">
        <RowBetween>
          {title ? <ThemedText.MediumHeader fontWeight={535}>{title}</ThemedText.MediumHeader> : <div />}
          <RateToggle
            currencyA={sorted ? currency0 : currency1}
            currencyB={sorted ? currency1 : currency0}
            handleRateToggle={handleRateChange}
          />
        </RowBetween>

        <CsGridContainer>
          <CsGridBox>
            <LightCard width="100%" padding="16px">
              <CsRowBetween>
                <ThemedText.BodyPrimary fontSize="12px">
                  <Trans>Min Price</Trans>
                </ThemedText.BodyPrimary>
                <ThemedText.DeprecatedMediumHeader   >
                  <HoverInlineText
                    maxCharacters={15}
                    text={formatTickPrice({
                      price: priceLower,
                      atLimit: ticksAtLimit,
                      direction: Bound.LOWER,
                    })}
                  />

                </ThemedText.DeprecatedMediumHeader>
                <ThemedText.DeprecatedMain fontSize="12px">
                  <Trans>
                    {quoteCurrency.symbol} per {baseCurrency.symbol}
                  </Trans>
                </ThemedText.DeprecatedMain>
                <ThemedText.DeprecatedSmall style={{ marginTop: '4px' }}>
                  <Trans>Your position will be 100% composed of {baseCurrency?.symbol} at this price</Trans>
                </ThemedText.DeprecatedSmall>
              </CsRowBetween>
            </LightCard>
            <LightCard width="100%" padding="16px">
              <CsRowBetween>
                <ThemedText.BodyPrimary fontSize="12px">
                  <Trans>Max Price</Trans>
                </ThemedText.BodyPrimary>
                <ThemedText.DeprecatedMediumHeader   >
                  <HoverInlineText
                    maxCharacters={13}
                    text={formatTickPrice({
                      price: priceUpper,
                      atLimit: ticksAtLimit,
                      direction: Bound.UPPER,
                    })}
                  />

                </ThemedText.DeprecatedMediumHeader>
                <ThemedText.DeprecatedMain fontSize="12px">
                  <Trans>
                    {quoteCurrency.symbol} per {baseCurrency.symbol}
                  </Trans>
                </ThemedText.DeprecatedMain>
                <ThemedText.DeprecatedSmall style={{ marginTop: '4px' }}>
                  <Trans>Your position will be 100% composed of {quoteCurrency?.symbol} at this price</Trans>
                </ThemedText.DeprecatedSmall>
              </CsRowBetween>
            </LightCard>
          </CsGridBox>

          <LightCard width="100%" padding="16px ">
            <CsRowBetween>
              <ThemedText.BodyPrimary fontSize="12px">
                <Trans>Current price</Trans>
              </ThemedText.BodyPrimary>
              <ThemedText.DeprecatedMediumHeader>
                {price && (
                  <HoverInlineText
                    maxCharacters={13}
                    text={price.toSignificant(6)}
                  />
                )}
              </ThemedText.DeprecatedMediumHeader>
              <ThemedText.DeprecatedMain fontSize="12px">
                <Trans>
                  {quoteCurrency.symbol} per {baseCurrency.symbol}
                </Trans>
              </ThemedText.DeprecatedMain>
            </CsRowBetween>
          </LightCard>



        </CsGridContainer>

      </AutoColumn>
    </AutoColumn>
  )

}


export const PositionPreviewV2 = ({
  position,
  title,
  inRange,
  baseCurrencyDefault,
  ticksAtLimit,
}: {
  position: Position
  title?: ReactNode
  inRange: boolean
  baseCurrencyDefault?: Currency
  ticksAtLimit: { [bound: string]: boolean | undefined }
}) => {
  const theme = useTheme()

  const currency0 = unwrappedToken(position.pool.token0)
  const currency1 = unwrappedToken(position.pool.token1)

  // track which currency should be base
  const [baseCurrency, setBaseCurrency] = useState(
    baseCurrencyDefault
      ? baseCurrencyDefault === currency0
        ? currency0
        : baseCurrencyDefault === currency1
          ? currency1
          : currency0
      : currency0
  )

  const sorted = baseCurrency === currency0
  const quoteCurrency = sorted ? currency1 : currency0

  const price = sorted ? position.pool.priceOf(position.pool.token0) : position.pool.priceOf(position.pool.token1)

  const priceLower = sorted ? position.token0PriceLower : position.token0PriceUpper.invert()
  const priceUpper = sorted ? position.token0PriceUpper : position.token0PriceLower.invert()

  const handleRateChange = useCallback(() => {
    setBaseCurrency(quoteCurrency)
  }, [quoteCurrency])

  const removed = position?.liquidity && JSBI.equal(position?.liquidity, JSBI.BigInt(0))

  return (
    <AutoColumn gap="sm" style={{ marginTop: '0rem' }}>
      <LightCard>
        <AutoColumn gap="sm">
          <RowBetween>
            <CsRowStart>
              <CurrencyLogo currency={currency0} />
              <ThemedText.BodySecondary ml="8px">{currency0?.symbol}</ThemedText.BodySecondary>
            </CsRowStart>
            <ThemedText.BodySecondary>{position.amount0.toSignificant(4)}</ThemedText.BodySecondary>
          </RowBetween>
          <RowBetween>
            <CsRowStart>
              <CurrencyLogo currency={currency1} />
              <ThemedText.BodySecondary ml="8px">{currency1?.symbol}</ThemedText.BodySecondary>
            </CsRowStart>
            <ThemedText.BodySecondary>
              <Trans>{position.amount1.toSignificant(4)}</Trans>
            </ThemedText.BodySecondary>
          </RowBetween>
          <RowBetween>
            <ThemedText.BodySecondary>
              <Trans>Fee Tier</Trans>
            </ThemedText.BodySecondary>
            <ThemedText.BodySecondary>
              <Trans>{position?.pool?.fee / 10000}%</Trans>
            </ThemedText.BodySecondary>
          </RowBetween>
        </AutoColumn>
      </LightCard>

      <AutoColumn gap="md">
        <RowBetween>
          {title ? <ThemedText.MediumHeader fontWeight={535}>{title}</ThemedText.MediumHeader> : <div />}
          <RateToggle
            currencyA={sorted ? currency0 : currency1}
            currencyB={sorted ? currency1 : currency0}
            handleRateToggle={handleRateChange}
          />
        </RowBetween>
        <CsPositionV2>
          <LightCard padding="12px">
            <AutoColumn gap="2px" justify='center'>
              <ThemedText.BodyPrimary fontSize="12px">
                <Trans>Min Price</Trans>
              </ThemedText.BodyPrimary>
              <ThemedText.DeprecatedMediumHeader textAlign="center">
                <HoverInlineText
                  maxCharacters={15}
                  text={formatTickPrice({
                    price: priceLower,
                    atLimit: ticksAtLimit,
                    direction: Bound.LOWER,
                  })}
                />

              </ThemedText.DeprecatedMediumHeader>
              <ThemedText.DeprecatedMain textAlign="center" fontSize="12px">
                <Trans>
                  {quoteCurrency.symbol} per {baseCurrency.symbol}
                </Trans>
              </ThemedText.DeprecatedMain>
              <ThemedText.DeprecatedSmall textAlign="center" style={{ marginTop: '4px' }}>
                <Trans>Your position will be 100% composed of {baseCurrency?.symbol} at this price</Trans>
              </ThemedText.DeprecatedSmall>
            </AutoColumn>
          </LightCard>

          <LightCard padding="12px">
            <AutoColumn gap="2px" justify="center">
              <ThemedText.BodyPrimary fontSize="12px">
                <Trans>Max Price</Trans>
              </ThemedText.BodyPrimary>
              <ThemedText.DeprecatedMediumHeader textAlign="center">
                <HoverInlineText
                  maxCharacters={13}
                  text={formatTickPrice({
                    price: priceUpper,
                    atLimit: ticksAtLimit,
                    direction: Bound.UPPER,
                  })}
                />

              </ThemedText.DeprecatedMediumHeader>
              <ThemedText.DeprecatedMain textAlign="center" fontSize="12px">
                <Trans>
                  {quoteCurrency.symbol} per {baseCurrency.symbol}
                </Trans>
              </ThemedText.DeprecatedMain>
              <ThemedText.DeprecatedSmall textAlign="center" style={{ marginTop: '4px' }}>
                <Trans>Your position will be 100% composed of {quoteCurrency?.symbol} at this price</Trans>
              </ThemedText.DeprecatedSmall>
            </AutoColumn>
          </LightCard>

        </CsPositionV2>
        <LightCard padding="12px ">
          <AutoColumn gap="2px" justify="center">
            <ThemedText.BodyPrimary fontSize="12px">
              <Trans>Current price</Trans>
            </ThemedText.BodyPrimary>
            <ThemedText.DeprecatedMediumHeader>
              {price && (
                <HoverInlineText
                  maxCharacters={15}
                  text={price.toSignificant(6)}
                />
              )}
            </ThemedText.DeprecatedMediumHeader>
            <ThemedText.DeprecatedMain textAlign="center" fontSize="12px">
              <Trans>
                {quoteCurrency.symbol} per {baseCurrency.symbol}
              </Trans>
            </ThemedText.DeprecatedMain>
          </AutoColumn>
        </LightCard>
      </AutoColumn>
    </AutoColumn>
  )

}