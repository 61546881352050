import React from "react";
import { JSX } from "react/jsx-runtime";

const WalletIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.0637 17.6455V19.168C13.0637 20.458 11.8637 21.5005 10.3862 21.5005C8.90868 21.5005 7.70117 20.458 7.70117 19.168V17.6455C7.70117 18.9355 8.90118 19.8505 10.3862 19.8505C11.8637 19.8505 13.0637 18.928 13.0637 17.6455Z" stroke="#C8704C" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.0624 15.5823C13.0624 15.9573 12.9574 16.3023 12.7774 16.6023C12.3349 17.3298 11.4273 17.7873 10.3773 17.7873C9.32735 17.7873 8.41984 17.3223 7.97734 16.6023C7.79734 16.3023 7.69238 15.9573 7.69238 15.5823C7.69238 14.9373 7.99237 14.3598 8.47237 13.9398C8.95987 13.5123 9.62734 13.2573 10.3698 13.2573C11.1123 13.2573 11.7799 13.5198 12.2674 13.9398C12.7624 14.3523 13.0624 14.9373 13.0624 15.5823Z" stroke="#C8704C" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.0637 15.5825V17.645C13.0637 18.935 11.8637 19.85 10.3862 19.85C8.90868 19.85 7.70117 18.9275 7.70117 17.645V15.5825C7.70117 14.2925 8.90118 13.25 10.3862 13.25C11.1287 13.25 11.7962 13.5125 12.2837 13.9325C12.7637 14.3525 13.0637 14.9375 13.0637 15.5825Z" stroke="#C8704C" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.5002 13.2274V14.7724C21.5002 15.1849 21.1702 15.5224 20.7502 15.5374H19.2801C18.4701 15.5374 17.7277 14.9449 17.6602 14.1349C17.6152 13.6624 17.7951 13.2199 18.1101 12.9124C18.3876 12.6274 18.7701 12.4624 19.1901 12.4624H20.7502C21.1702 12.4774 21.5002 12.8149 21.5002 13.2274Z" stroke="#C8704C" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.5 12.875V11.375C6.5 9.335 7.73 7.91 9.6425 7.67C9.8375 7.64 10.04 7.625 10.25 7.625H17C17.195 7.625 17.3825 7.63249 17.5625 7.66249C19.4975 7.88749 20.75 9.32 20.75 11.375V12.4625H19.19C18.77 12.4625 18.3875 12.6275 18.11 12.9125C17.795 13.22 17.615 13.6625 17.66 14.135C17.7275 14.945 18.47 15.5375 19.28 15.5375H20.75V16.625C20.75 18.875 19.25 20.375 17 20.375H15.125" stroke="#C8704C" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke={props?.color}/>
  </svg>
  );
};

export default WalletIcon;
