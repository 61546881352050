import { Box } from 'rebass/styled-components'
import styled from 'styled-components'
import { Gap } from 'theme'

// TODO(WEB-1983):
// Setting `width: 100%` by default prevents composability in complex flex layouts.
// Same applies to `RowFixed` and its negative margins. This component needs to be
// further investigated and improved to make UI work easier.
const Row = styled(Box)<{
  width?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  borderRadius?: string
  gap?: Gap | string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  gap: ${({ gap, theme }) => gap && (theme.grids[gap as Gap] || gap)};
`

export const RowBetween = styled(Row)`
  justify-content: space-between;
  gap: 24px;
  @media screen and (max-width: 600px){
    gap: 8px;
    justify-content: space-between !important;
  }
`
export const RowBetweenV2 = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  @media screen and (max-width: 600px){
    gap: 14px;
    display: flex;
    flex-direction: column;
  }
`
export const RowEndBetween = styled(Row)`
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  width: auto;
  @media screen and (max-width: 600px){
    justify-content: space-between;
    width: 100%;
  }
`
export const RowStartBetween = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: auto;
  @media screen and (max-width: 600px){
    justify-content: space-between;
    width: 100%;
  }
`

export const RowFlat = styled.div`
  display: flex;
  align-items: flex-end;
`

export const AutoRow = styled(Row)<{ gap?: string; justify?: string }>`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`

export const RowFixed = styled(Row)<{ gap?: string; justify?: string }>`
  width: fit-content;
  margin: ${({ gap }) => gap && `-${gap}`};
  padding: 0px;
  display: flex;
  justify-content: flex-start !important;
  background: transparent !important;
  @media screen and (max-width: 600px){
    width: 100%;
    margin:0;
    justify-content: space-between ;
  }
`

export default Row
