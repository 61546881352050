import styled from 'styled-components'

import { ReactComponent as FacebookI } from './images/facebook.svg'
import { ReactComponent as TelegramI } from './images/telegram.svg'
import { ReactComponent as TwitterI } from './images/twitter-safe.svg'

export const FacebookIcon = styled(FacebookI)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#98A1C0'};
  opacity: 1;
`

export const TwitterIcon = styled(TwitterI)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#98A1C0'};
  opacity: 1;
`

export const TelegramIcon = styled(TelegramI)<{ size?: number; fill?: string }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  fill: ${({ fill }) => fill ?? '#98A1C0'};
  opacity: 1;
`
