import { ReactNode, useCallback, useState } from 'react'
import { HelpCircle } from 'react-feather'
import styled from 'styled-components'

import Tooltip from '../Tooltip'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  font-size: 12px;
  border-radius: 12px;

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionMark = styled.span`
  font-size: 14px;
  margin-left: 8px;
  align-items: center;
  color: ${({ theme }) => theme.neutral2};
  margin-top: 2.5px;
`

export default function QuestionHelper({ text }: { text: ReactNode; size?: number }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <span style={{ marginLeft: 4, display: 'flex', alignItems: 'center' }}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <QuestionMark>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 7.99951C0.5 3.86026 3.86 0.499512 8 0.499512C12.1475 0.499512 15.5 3.86026 15.5 7.99951C15.5 12.1403 12.1475 15.4995 8 15.4995C3.86 15.4995 0.5 12.1403 0.5 7.99951ZM7.34 5.15701C7.34 4.79776 7.64 4.49701 8 4.49701C8.36 4.49701 8.6525 4.79776 8.6525 5.15701V8.47201C8.6525 8.83276 8.36 9.12451 8 9.12451C7.64 9.12451 7.34 8.83276 7.34 8.47201V5.15701ZM8.0075 11.5103C7.64 11.5103 7.3475 11.2103 7.3475 10.8503C7.3475 10.4903 7.64 10.1978 8 10.1978C8.3675 10.1978 8.66 10.4903 8.66 10.8503C8.66 11.2103 8.3675 11.5103 8.0075 11.5103Z" fill="#777E8F" />
            </svg>
          </QuestionMark>
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
