import { Percent } from '@itdeltalabs/trustdex-sdk-core'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components'

import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 10px;
  padding: 12px 0px;
  color: ${({ theme }) => theme.neutral2};
`

const HeaderButtonContainer = styled(RowFixed)`
  padding: 0;
  gap: 16px;
`

export default function SwapHeader({
  autoSlippage,
  chainId,
  trade,
}: {
  autoSlippage: Percent
  chainId?: number
  trade?: InterfaceTrade
}) {
  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <CsTextLabel>
          <Trans>Swap</Trans>
        </CsTextLabel>
        {/* <SwapBuyFiatButton /> */}
      </HeaderButtonContainer>
      
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} trade={trade} />
      
    </StyledSwapHeader>
  )
}

const CsTextLabel = styled(Text)`
  font-size: 28px;
  font-weight: 600;
  color: ${({theme}) => theme.neutral2};
  @media screen and (max-width: 600px){
    font-size: 20px;
  }
`