import styled from 'styled-components'

export const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width ?? '100%'};
  background: ${({ theme }) => theme.surface2};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border};
  cursor: pointer;
  outline: none;
  padding: 2px;
`

export const ToggleElement = styled.span<{ isActive?: boolean; fontSize?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 20px;
  border-radius: 8px;
  justify-content: center;
  height: 100%;
  background: ${({ theme, isActive }) => (isActive ? theme.neutral2 : 'none')};
  color: ${({ theme, isActive }) => (isActive ? theme.surface1 : theme.neutral2)};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: 535;
  white-space: nowrap;
 
`
